import FeaturesNotLoggedInLayout from '@/components/features/featuresNotLoggedInLayout';
import ForgotContentForm from '@/pages/public/forgot/components/ForgotContentForm';
import { useTranslation } from 'react-i18next';
import useStateHooks from './useStateHooks';

/**
 *登录
 */
const ForgotView = () => {
  const props = useStateHooks();
  const { t } = useTranslation();

  return (
    <FeaturesNotLoggedInLayout title={t('忘记密码')}>
      {/*登录表单*/}
      <ForgotContentForm {...props} />
    </FeaturesNotLoggedInLayout>
  );
};
export default ForgotView;
