import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseTabs from '@/components/base/baseTabs';
import PageEnum from '@/enums/pageEnum';
import {
  ForgotType,
  ForgotTypeEnum,
} from '@/pages/public/forgot/useStateHooks';
import { history } from '@@/core/history';
import { Button, Image, cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { WAY_TO_REGISTER } from '@/pages/public/register/useStateHooks';
import { VerifiedCodeEnum, VerifiedCodeTypeEnum } from '@/enums/businessEnum';

/**
 * 忘记密码表单组件
 * @param {IProps} props - 组件属性
 * @returns {JSX.Element} - 忘记密码表单的 JSX 元素
 */
export default (props: ForgotType) => {
  const { t } = useTranslation();
  const {
    sendMailApiRequest, // 发送验证码请求
    retrievePasswordSubmit,
    submitLoading,
    countdown,
    forGotForm,
    setForGotForm,
    currentForgot,
    setCurrentForgot,
    forgotOption,
    setTargetDate,
    countdownEmail,
    setTargetDateEmail,
    sendMailLoading
  } = props;
  const { currentAreaCode } = useModel('areaCode');
  const { isMobile } = useModel('system');
  return (
    <div className="flex flex-col gap-4">
      {/*找回方式区分*/}
      <BaseTabs
        tabsPropsProps={{
          className: 'w-full',
          classNames: {
            tabList: 'w-full border-[1px]',
          },
        }}
        value={currentForgot}
        variant="bordered"
        onChange={setCurrentForgot}
        options={forgotOption}
      />
      {/* 手机号 */}
      {currentForgot === '0' && (
        <div className="flex flex-col gap-2 ">
          <div
            className={cn(`flex items-center border-1 shadow-sm  rounded-md !h-[56px]`, {
              'border-[#efefef]': !isMobile,
              'border-borderColor': isMobile,
            })}
          >
            <div
              className="flex items-center h-full sm:cursor-pointer"
              onClick={() => {
                history.push(
                  `${PageEnum.CHOOSE_AREA}?targetUrl=${PageEnum.FORGOT}&currentForgot=${currentForgot}&isKeepAlive=${1}`,
                );
              }}
            >
              <Image
                classNames={{
                  wrapper:
                    'w-[20px] ml-2  h-[20px] flex-shrink-0 rounded-full object-cover',
                }}
                className="w-[20px] h-[20px] flex-shrink-0 rounded-full object-cover"
                src={(currentAreaCode as any)?.iconUrl}
              />
              <span>+{(currentAreaCode as any)?.areaCode}</span>
              <FluentTriangleDown12Filled
                className="text-backContrastColor ml-2"
                width="8px"
                height="8px"
              />
            </div>

            <BaseInput
              classNames={{
                input: 'sm:text-[#0E0E0E] !border-none ',
                label: 'sm:!text-[#0E0E0E]',
                inputWrapper: 'sm:text-[#0E0E0E] !border-none shadow-none',
              }}
              value={forGotForm?.phone}
              onChange={(e) => {
                setForGotForm((prevState: any) => {
                  return {
                    ...prevState,
                    phone: e.target.value,
                  };
                });
              }}
              label={t('手机号')}
              isRequired
            />
          </div>
        </div>
      )}

      {currentForgot === '1' && (
        <div className="flex flex-col gap-2 !h-[56px]">
          <BaseInput
            classNames={{
              input: 'sm:text-[#0E0E0E]',
              label: 'sm:!text-[#0E0E0E]',
              inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
            }}
            value={forGotForm?.email}
            onChange={(e) => {
              setForGotForm((prevState: any) => {
                return {
                  ...prevState,
                  email: e.target.value,
                };
              });
            }}
            label={t('邮箱')}
            isRequired
          />
        </div>
      )}

      {/* 验证码输入框 */}
      <div className="flex flex-col gap-2 relative">
        <BaseInput
          value={forGotForm?.captcha}
          onChange={(e) => {
            setForGotForm((prevState: any) => {
              return {
                ...prevState,
                captcha: e.target.value,
              };
            });
          }}
          isShowClear={false}
          isRequired
          type="text"
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          label={t('验证码')}
        />
        <Button
          spinner={<LoadingSvg />}
          isLoading={sendMailLoading}
          onClick={() => {
            const emailRegex = /^[a-zA-Z0-9_.!#$%*-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,9}$/;
            if (currentForgot === ForgotTypeEnum.EMAIL && !emailRegex.test(forGotForm?.email)) {
              return toast.error(t('请输入正确的邮箱'));
            }

            if (currentForgot === ForgotTypeEnum.PHONE && countdown !== 0) {
              return toast.error(t('验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！', {
                times: 30,
              }));
            }
            if (currentForgot === ForgotTypeEnum.EMAIL && countdownEmail !== 0) {
              return toast.error(t('验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！', {
                times: 30,
              }));
            }
            if (
              currentForgot === ForgotTypeEnum.PHONE &&
              !forGotForm?.phone.trim()
            ) {
              return toast.error(t('请输入手机号'));
            }
            if (
              currentForgot === ForgotTypeEnum.EMAIL &&
              !forGotForm?.email.trim()
            ) {
              return toast.error(t('请输入邮箱'));
            }
            if (currentForgot === ForgotTypeEnum.PHONE) {
              sendMailApiRequest({
                to: forGotForm?.phone.trim(),
                type: VerifiedCodeEnum.RESET_PASSWORD,
                sendType: VerifiedCodeTypeEnum.PHONE,
                areaCode: (currentAreaCode as any)?.areaCode,
              });
            } else {
              sendMailApiRequest({
                to: forGotForm?.email.trim(),
                sendType: VerifiedCodeTypeEnum.EMAIL,
                type: VerifiedCodeEnum.RESET_PASSWORD,
              });
            }

          }}
          className="absolute right-1 top-0 bottom-0 my-2 flex items-center justify-center  px-3 focus:outline-none bg-[transparent] !text-primary text-base cursor-pointer "
          type="button"
        >
          {
            currentForgot === ForgotTypeEnum.PHONE && <>
              {countdown !== 0 ? (
                <span className="text-foreground pr-3">
                                         {Math.round(countdown / 1000)}
                                       </span>
              ) : (
                t('发送验证码')
              )}
            </>
          }
          {
            currentForgot === ForgotTypeEnum.EMAIL && <>
              {countdownEmail !== 0 ? (
                <span className="text-foreground pr-3">
                                         {Math.round(countdownEmail / 1000)}
                                       </span>
              ) : (
                t('发送验证码')
              )}
            </>
          }
        </Button>
      </div>
      {/* 密码输入框 */}
      <div className="flex flex-col gap-2">
        <BaseInput
          value={forGotForm?.password}
          onChange={(e) => {
            setForGotForm((prevState: any) => {
              return {
                ...prevState,
                password: e.target.value,
              };
            });
          }}
          isRequired
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          label={t('密码')}
          type="password"
        />
      </div>
      {/* 确认密码输入框 */}
      <div className="flex flex-col gap-2">
        <BaseInput
          value={forGotForm?.repeatPassword}
          onChange={(e) => {
            setForGotForm((prevState: any) => {
              return {
                ...prevState,
                repeatPassword: e.target.value,
              };
            });
          }}
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            label: 'sm:!text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          }}
          isRequired
          label={t('确认密码')}
          type="password"
        />
        <Button
          spinner={<LoadingSvg />}
          isLoading={submitLoading}
          onClick={retrievePasswordSubmit}
          className="mt-6 mainColorButton"
        >
          {t('点击找回密码')}
        </Button>
        <Button
          onClick={props.goLogin}
          className="w-full mx-auto rounded-md  bg-backgroundAuxiliaryColor  sm:!bg-[#f4f4f5] sm:border-none sm:text-[#0E0E0E] h-[45px]"
        >
          {t('登录')}
        </Button>
      </div>
    </div>
  );
};
