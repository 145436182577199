import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage, useSessionStorage } from '@/hooks/useStorage';
import { retrievePasswordApi, sendMailApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { history } from '@umijs/max';
import { useCountDown, useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { WAY_TO_REGISTER } from '@/pages/public/register/useStateHooks';

// 找回密码方式
export enum ForgotTypeEnum {
  //手机号
  PHONE = '0',
  //邮箱
  EMAIL = '1',
}

export default function useStateHooks() {
  const { t } = useTranslation();
  // 找回密码方式选择和当前选中的注册方式
  const [forgotOption] = useState([
    {
      value: ForgotTypeEnum.EMAIL,
      text: t('邮箱'),
    },
    {
      value: ForgotTypeEnum.PHONE,
      text: t('手机号'),
    },
  ]);
  //是否缓存数据
  const [urlState] = useUrlState<any>({});
  const { isKeepAlive, currentForgot: defaultCurrentForgot } = urlState || {};
  const [currentForgot, setCurrentForgot] = useState(defaultCurrentForgot ?? ForgotTypeEnum.EMAIL);
  //是否显示区号
  const [isShowAreaCode, setIsShowAreaCode] = useState(true);

  //表单
  const [forGotForm, setForGotForm] = useSessionStorage({
    key: CacheEnum.APP_FIND_PASSWORD_CACHE,
    defaultValue: {
      email: '',
      captcha: '',
      password: '',
      phone: '',
      repeatPassword: '',
    },
  });
  useMount(() => {
    if (!isKeepAlive) {
      setForGotForm({
        email: '',
        captcha: '',
        password: '',
        phone: '',
        repeatPassword: '',
      });
    } else {
      setIsShowAreaCode(true);
    }
  });
  //去登录
  const goLogin = () => {
    history.push(PageEnum.LOGIN);
  };
  //手机验证码
  const [targetDate, setTargetDate] = useLocalStorage({
    key: CacheEnum.APP_FORGOT_CODE,
    defaultValue: 0,
  });
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
    },
  });
  // 注册邮箱
  const [targetDateEmail, setTargetDateEmail] = useLocalStorage({
    key: CacheEnum.APP_FORGOT_CODE_EMAIL,
    defaultValue: 0,
  });
  const [countdownEmail] = useCountDown({
    targetDate: targetDateEmail,
    onEnd: () => {
    },
  });
  //发送邮件
  const { run: sendMailApiRequest, loading: sendMailLoading } = useReq(sendMailApi, {
    manual: true,
    loadingDefault: false,
    onSuccess: (res) => {
      if (currentForgot === WAY_TO_REGISTER.PHONE) {
        setTargetDate(Date.now() + (30000));
      } else {
        setTargetDateEmail(Date.now() + (30000));
      }
      toast.success(t('发送成功'));
    },
    onError() {
      if (currentForgot === ForgotTypeEnum.PHONE) {
        setTargetDate(Date.now());
      } else {
        setTargetDateEmail(Date.now());
      }
    },
  });
  //忘记密码请求
  const { run: retrievePasswordReq, loading: submitLoading } = useReq(
    retrievePasswordApi,
    {
      loadingDefault: false,
      manual: true,
      onSuccess: (res) => {
        toast.success(t('找回密码成功'));
        history.push(PageEnum.LOGIN);
      },
    },
  );
  const { currentAreaCode } = useModel('areaCode');

  //提交
  const retrievePasswordSubmit = () => {
    if (!forGotForm?.captcha?.replace(/\s+/g, '')) return toast.error(t('请输入验证码'));
    if (!forGotForm?.password?.replace(/\s+/g, '')) return toast.error(t('请输入密码'));
    if (!forGotForm?.repeatPassword?.replace(/\s+/g, '')) return toast.error(t('请再次输入密码'));
    if (forGotForm.password?.replace(/\s+/g, '') !== forGotForm.repeatPassword?.replace(/\s+/g, ''))
      return toast.error(t('两次输入密码不一致'));
    retrievePasswordReq({
      ...forGotForm,
      email: forGotForm.email?.replace(/\s+/g, ''),
      phone: forGotForm.phone?.replace(/\s+/g, ''),
      password: forGotForm.password?.replace(/\s+/g, ''),
      areaCode: (currentAreaCode as any)?.areaCode,
    });
  };

  return {
    goLogin, // 去登录
    retrievePasswordSubmit, // 提交
    sendMailApiRequest,
    setTargetDate,
    countdown,
    submitLoading,
    forGotForm,
    setForGotForm,
    isShowAreaCode,
    setIsShowAreaCode,
    forgotOption,
    currentForgot,
    setCurrentForgot,
    countdownEmail,
    targetDateEmail,
    setTargetDateEmail,
    sendMailLoading,
  };
}
export type ForgotType = ReturnType<typeof useStateHooks>;
